import React, { useState } from 'react';
import PdfViewer from './PdfPreviewer';

function CustomMeta({ files, setFiles, setShowSettingsDialog }) {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  
  const handleFileClick = (index) => {
    setSelectedFileIndex(index);
  };

  const handleMetadataChange = (metadataKey, value) => {
    const updatedFiles = [...files];
    updatedFiles[selectedFileIndex].PrintMetadata = {
      ...updatedFiles[selectedFileIndex].PrintMetadata,
      [metadataKey]: value
    };
    updatedFiles[selectedFileIndex].customApplied = true;
    setFiles(updatedFiles);
  };


  function increaseCopiesNumber(e){
    e.preventDefault();
    const currCopies = files[selectedFileIndex].PrintMetadata.copiesNo;
    handleMetadataChange('copiesNo',currCopies+1)
  }

  function decreaseCopiesNumber(e){
    e.preventDefault();
    const currCopies = files[selectedFileIndex].PrintMetadata.copiesNo;
    handleMetadataChange('copiesNo',currCopies <= 1 ? currCopies : currCopies - 1)
  }

  function inputNoChange(e){
    let copiesNo = e.target.value;
    handleMetadataChange('copiesNo',parseInt(copiesNo));
  }


  return (
    <>
      <div style={{display:'flex', justifyContent:'space-between' }}>
        <h3>Settings Dialog</h3>
        <button style={{height:'20px', background:'red', color:'white', border:'none' , fontWeight:'bold'}} onClick={()=>{setShowSettingsDialog(false)}}>X</button>
      </div>
      <div className="custom-meta-container">     
        <div className='list-preview-div'>
          <div className="file-list" >
            <h3>Files</h3>
            <ul>
              {files.map((fileObj, index) => (
                <li
                  key={index}
                  onClick={() => handleFileClick(index)}
                  className={selectedFileIndex === index ? 'selected' : ''}
                  style={{ cursor: 'pointer' }}
                >
                  {fileObj.file.name}
                </li>
              ))}
            </ul>
          </div>
          <button className='preview-button' onClick= {()=> {setPreviewOpen(true); window.showPreview(files[selectedFileIndex].file)}} >Preview</button>
        </div>
        <div>
          <h3>Metadata Options</h3>
          <div className="metadata-options" >
            {files[selectedFileIndex] && (
            <div className='settings-buttons'>
              <div className="color-options" style={{}}>
                <div>
                  <h4 className="color-title" style={{}}>
                    select color
                  </h4>
                </div>
                <div className="color-buttons" style={{}}>
                  <button className={ files[selectedFileIndex].PrintMetadata.colour? 'selected' : ''} onClick={(e)=>{e.preventDefault(); handleMetadataChange('colour', true)}} >B&W</button>
                  <button className={!files[selectedFileIndex].PrintMetadata.colour? 'selected' : ''} onClick={(e)=>{e.preventDefault(); handleMetadataChange('colour', false)}}>COLOR</button>
                </div>
              </div>
              <div className="layout-options" style={{}}>
                <div>
                  <h4 className="layout-title" style={{}}>
                    select layout
                  </h4>
                </div>
                <div className="layout-buttons" style={{}}>
                  <button className={ files[selectedFileIndex].PrintMetadata.layout === 'portrait' ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); handleMetadataChange('layout', 'portrait')} } >Portrait</button>
                  <button className={ files[selectedFileIndex].PrintMetadata.layout === 'landscape' ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); handleMetadataChange('layout', 'landscape')}} >Landscape</button>
                </div>
              </div>
              <div className="side-options" style={{}}>
                <div>
                  <h4 className="side-title" style={{}}>
                    select sides
                  </h4>
                </div>
                <div className="side-buttons" style={{}}>
                  <button className={ files[selectedFileIndex].PrintMetadata.sides === 'single' ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); handleMetadataChange('sides', 'single')} } >Single</button>
                  <button className={ files[selectedFileIndex].PrintMetadata.sides === 'double' ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); handleMetadataChange('sides', 'double')} } >Double</button>
                </div>
              </div>
              <div className="papersize-options">
                    <div>
                      <h4 className="papersize-title" style={{}}>
                        paper size
                      </h4>
                    </div>
                    <div className="papersize-textbox" >
                      <span className="papersize-text" style={{}}>A4</span>
                    </div>
              </div>
              <div className='copies-option'>
                  <div>
                    <h4 style={{margin:"0px"}}>
                      copies
                    </h4>
                  </div>
                  <div className='copies' style={{height:'50px'}}>
                    <input className='copies-No' type="number" min={1} value={files[selectedFileIndex].PrintMetadata.copiesNo} onChange={inputNoChange}></input>
                    <div className='copies-buttons'>
                      <button onClick={increaseCopiesNumber} className="copies-no-symbol" style={{}}>&#x25B2;</button>
                      <button onClick={decreaseCopiesNumber} className="copies-no-symbol" style={{}}>&#x25BC;</button>
                    </div>
                  </div>    
              </div>
              <div className="pages-options">
                    <h4 className="pages-title">Pages to Print</h4>
                    <select style={{padding:(files[selectedFileIndex].PrintMetadata.selectedPages === 'custom' )? '5px':'15px', backgroundColor:'ButtonFace' , marginBottom:'5px'}}
                        value={files[selectedFileIndex].PrintMetadata.selectedPages}
                        onChange={(e) =>{ 
                          handleMetadataChange('selectedPages', e.target.value)
                          console.log(files[selectedFileIndex])
                        }}
                    >
                        <option value="all">All Pages</option>
                        <option value="first">First Page Only</option>
                        <option value="even">Even Pages Only</option>
                        <option value="odd">Odd Pages Only</option>
                        <option value="custom">Custom Range</option>
                    </select>
                    {(files[selectedFileIndex].PrintMetadata.selectedPages === 'custom') && (
                        <input
                            type="text"
                            style={{width:'128px'}}
                            placeholder="e.g. 1-5"
                            value={files[selectedFileIndex].PrintMetadata.customRange}
                            onChange={(e) => {
                              handleMetadataChange('customRange', e.target.value)
                            }}
                        />
                    )}
              </div>
            </div>
            
          )}
          </div>
        </div>  
      </div>
      {previewOpen && <div className="modal-overlay"> 
        <div className="modal-dialog">
           <PdfViewer previewOpen={previewOpen} setPreviewOpen={setPreviewOpen}/>
        </div>
      </div>}
    </>
  );
  
}

export default CustomMeta;

