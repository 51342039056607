import React from "react";

function Footer(){
    return (
        <section id="contact">
            <div className="footer">
                <div style={{}} className="footer-logos">
                    <a href="https://www.zeldatech.in/" target="_blank"><img src="images/EROX/FOOTER/internet.svg" alt="fb"></img></a>
                    <a href="https://www.instagram.com/zeldatechinnovations?igsh=MW1lZzgyYXFhZjJ1OA==" target="_blank"><img src="images/EROX/FOOTER/instagram.svg" alt="TWEET"></img></a>
                    <a href="https://www.linkedin.com/company/zeldatech-innovations-pvt-ltd/?viewAsMember=true" target="_blank"><img src="images/EROX/FOOTER/linkedin.svg" alt="LINKED-IN"></img></a>
                </div>
                <div>
                    <img src="images/EROX/FOOTER/EROX-FOOTER-LOGO.svg" alt="footerlogo" className="footer-erox-img" style={{}}></img>
                </div>
            </div>
        </section>
    )

}

export default Footer