import React from "react";

function UspErox(){
    return(
        <>
            <div className='usperox'>
                
                    <img src="images/EROX/Website_Icon/Group-5.svg" alt="group7" className="erox-usp-imgs" style={{}}>
                    </img>
                    <img src="images/EROX/Website_Icon/Group-6.svg" alt="group8" className="erox-usp-imgs" style={{}}>
                    </img>
        
                    <img src="images/EROX/Website_Icon/Group-139.svg" alt="group7" className="erox-usp-imgs" style={{}}>
                    </img>
                    <img src="images/EROX/Website_Icon/Group-140.svg" alt="group8" className="erox-usp-imgs" style={{}}>
                    </img>
                
            </div>
        </>
    )
        }

export default UspErox;