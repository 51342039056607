import React, { useState } from 'react';

export default function ProceedToUpload({setProceedToUpload,handleUpload,setPrintNow}){
    const [selectedIndex, setSelectedIndex] = useState(0);
    
    function handleClick(index){
        setSelectedIndex(index);
    }

    return (
        <>
            <div style={{display:"flex",flexDirection:"column",width:"250px",}}>
              <div style={{display:'flex', justifyContent:'space-between' }}>
                <h3>Upload Box</h3>
                <button style={{height:'20px', background:'red', color:'white', border:'none' , fontWeight:'bold'}} onClick={()=>{setProceedToUpload(false)}}>X</button>
              </div>
              <ul style={{border: "1px solid #ccc",borderRadius: "8px",paddingLeft:"10px",paddingRight:"10px"}}>
                <li onClick={() => handleClick(0)} className={selectedIndex === 0 ? 'selected' : ''} style={{ cursor: 'pointer',listStyle:'none' }}>
                  <h1>print later</h1>  
                </li>
                <li onClick={() => {handleClick(1); setPrintNow(true)}} className={selectedIndex === 1 ? 'selected' : ''} style={{ cursor: 'pointer',listStyle:'none' }}>
                  <h1>print now</h1>
                </li>
              </ul>
              <div style={{margin:"auto"}}>
                <button className="upload-button" type="button" onClick={handleUpload}>Upload</button>
              </div>
            </div>
        </>    
      )
}