import React, {useEffect, useState} from "react"; 
import { ref,uploadBytesResumable } from 'firebase/storage';
import {storage, } from "../../firebase";
import { auth,} from "../../userAuth";
import CustomMeta from '../../components/CustomMeta';
import ProceedToUpload from '../../components/ProceedToUpload';
import SelectOrder from "../../components/selectOrder";
import {v4 as uuidv4} from 'uuid'; 
import axios from "axios";

export default function Userprintsettings() {

    const [files, setFiles] = useState([]);
    const [selectedPages, setSelectedPages] = useState('all');
    const [customRange, setCustomRange] = useState('');
    const [uploadSuccess, setUploadsuccess] = useState(false);
    const [eachprogressbar,setEachprogressbar] = useState([]);
    const [copies, setCopies] = useState(1);
    const [layout, setLayout] = useState(true); 
    const [color, setColor] = useState(true);
    const [singleSide , setSingleSide] =useState(true);
    const [showSettingsDialog, setShowSettingsDialog] = useState(false); 
    const [proceedToUpload, setProceedToUpload] = useState(false);
    const [removeProgressbar,setRemoveProgressbar] = useState(false);
    const [progresstatus,setProgresstatus] = useState(0);
    const [upload, setupload] = useState(false);
    const [fileinfo,setfileinfo] = useState(0);
    const [printNow, setPrintNow] = useState(false);
    const [globalUserId, setGlobalUserId] = useState(null); 
    let orderIdArray = {}; 
    
    useEffect(()=>{if(localStorage.getItem('userid')!==null){
      let globalid = localStorage.getItem('userid');
      setGlobalUserId(globalid);
    }},[]);
    
    function handleProgressItems(filelist){
  
      let fileprogressbars = [];
      filelist.forEach((file)=>{fileprogressbars.push({value:0})});
      
      setEachprogressbar(progressperfile =>{
        return [...progressperfile,...fileprogressbars];
      })
    
    }
    
    function getProgressbarstatus(){
      
      let avgProgress = 0;
      
      if (eachprogressbar.length!==0){
      eachprogressbar.forEach((val)=>{
        avgProgress+=val.value;        
      })
      
      avgProgress = avgProgress/eachprogressbar.length;
    }

      if (avgProgress===100 && removeProgressbar===false){
        setRemoveProgressbar(true);
        setupload(false);
        setUploadsuccess(true)
        setTimeout(()=>{
          setUploadsuccess(false);
          setProgresstatus(0);
          setUploadsuccess(false);
        },10000);
      }
      return avgProgress;
    }

    useEffect(()=>{
    if(removeProgressbar===false && upload===true){
      let progress = getProgressbarstatus();
      setProgresstatus(progress);
    }
    },[eachprogressbar,removeProgressbar,upload]);
    
    const handleFileChange = (event) => {
      const fileList = event.target.files;
      const updatedFiles = Array.from(fileList).map(file => ({
        file,
        PrintMetadata: {copiesNo: copies,sides:singleSide?'single':'double',layout:layout? 'portrait':'landscape',customRange , selectedPages, colour:color?true:false, papersize:"A4"},
        customApplied: false 
      }));
      setFiles([...files, ...updatedFiles]);
      handleProgressItems(updatedFiles);
    };

    async function handleUpload(event) {
          event.preventDefault(); 
          
          if(files.length===0){
            window.alert('select atleast single file first')
            return;
          }
          
          const userin = auth.currentUser;
          let userid = null;
          let onetime = 1;
          if(userin!=null){
            userid = userin.uid;
          }
          
          // if (!userin) {   
          //     window.alert('Please log in first.');
          //     return;
          // } 
          
          setupload(true);
          setRemoveProgressbar(false);

          const orderIdNo = Math.random().toString(36).substring(2, 8);

          let orderfilenames = files.map((item)=>{ return item.file.name}); 
          orderIdArray = {[orderIdNo]:[...orderfilenames]};

          
          if(!localStorage.getItem('userid')){
            localStorage.setItem('userid',uuidv4());
          }
          
          userid = userid || localStorage.getItem('userid');
          if(!globalUserId){
            setGlobalUserId(userid);
          }
          
          files.forEach(async (eachfile,index)=>{
          try {
                let progressindex = index;
                let filename = eachfile.file.name;
                let uid = userid;   
                const storageref = ref(storage, `${uid}/${orderIdNo}`);
                const storageref2 = ref(storageref,`${filename}`);    
                const updatedmetada = {copiesNo: copies,sides:singleSide?'single':'double',layout:layout? 'portrait':'landscape',customRange ,
                 selectedPages, colour:color?true:false, papersize:"A4"}                
                const metadata = {contentType:'application/pdf',customMetadata:{orderIdNo,...eachfile.PrintMetadata,...updatedmetada},uuid:uid};
                
                const uploadedFile = uploadBytesResumable(storageref2,eachfile.file,metadata);

                uploadedFile.on("state_changed",(snapshot)=>{
                    let datatransfered = (snapshot.bytesTransferred / snapshot.totalBytes)*100;
                    let index = progressindex;
                    setEachprogressbar((eachprogress)=>{
                      let newprogress = [...eachprogress];
                      newprogress[index].value = datatransfered;
                      return newprogress;
                    });
                },
                  (error)=>{
                    console.log('this is upload error')
                    console.log(error);
                },
                ()=>{
                      if(onetime===1 && !printNow){
                        
                        if(!localStorage.getItem('orderIdNo')){  
                          localStorage.setItem('orderIdNo',JSON.stringify(orderIdArray));
                          }
                        else{
                            let pendingids = JSON.parse(localStorage.getItem('orderIdNo'));
                            pendingids = {...pendingids,...orderIdArray};
                            localStorage.setItem('orderIdNo',JSON.stringify(pendingids));
                        }
                    }

                    onetime = 0;
                }
              )
            }
          catch(error) {
              console.log('this is in catch block')
              console.error(error);
          }
    })
    setProceedToUpload(false);
    
    if(printNow){
      try {
        const response = await axios.post(`http://web.erox.services/printit?user=${userid}&orderids=${orderIdNo}`,{filenames:orderfilenames},
          {headers: {
          'Content-Type': 'application/json'
          }
      });
        console.log(response);
      }
      catch(error){
        console.log(error);
      }
      setPrintNow(false);
    }
  }

    function vieworder(e){
      e.preventDefault();
      setShowSettingsDialog(true);
      setfileinfo(1);
    }

    function showProceedToPrint(e){
      e.preventDefault();
      setProceedToUpload(true);
    }

    const clearFiles = (e)=>{
      e.preventDefault();
      setProgresstatus(0);
      setEachprogressbar([]);
      setFiles([]);
    }

    function increaseCopiesNumber(e){
      e.preventDefault();
      setCopies((copies)=> copies+1 );
    }

    function decreaseCopiesNumber(e){
      e.preventDefault();
      setCopies(copies => copies<=1?copies:copies-1);
    }

    function inputNoChange(e){
      let copieno = e.target.value;
      setCopies(parseInt(copieno));
    }

        
    return (
      <section id="home">
        <form className="user-settings-form">
            <div className="input-container">    
              <button className="input-button">     
                <input type="file" accept=".pdf" onChange={handleFileChange} className="file-input" multiple></input>
                {files.length===0 && <img src="images/EROX/Website_Icon/upload.svg" alt="no-upload" className="upload-icon" style={{}}></img>}
                {files.length===0 && <div><span> Choose File / Drop File </span> </div> } 
                {<ul>
                    {files.map((file, index) => (
                        <div key={index}>{file.file.name}</div>
                    ))}
                </ul>} 
              </button>
              <div className="files-buttons">
                <button className ='configure-settings-button' onClick={(e)=>{e.preventDefault(); setShowSettingsDialog(true); setfileinfo(0)}}>Multi-File Settings</button>
                <button className ="configure-settings-button" onClick={clearFiles}>Clear Files</button>
              </div>
              <div className ="upload-info">
                {progresstatus>0 ?
                  <div style ={{borderWidth: "1px",borderStyle: "solid",width:"300px",display:"grid",alignItems:"center",height:"20px"}}>
                    <span style ={{width:`${progresstatus}%`,backgroundColor:"red",marginLeft:"0px",height:"20px"}}></span>
                    {`${Math.round(progresstatus)}%`}
                  </div> : <div></div>}
                {uploadSuccess &&
                  <span style={{color:"green"}}>
                    upload successful
                  </span>}
              </div>
            </div>
            <div className="settings-button-divs">
              <div className="settings-buttons">
                <div className='copies-option'>
                    <div>
                      <h4 style={{margin:"0px"}}>
                        Copies
                      </h4>
                    </div>
                    <div className='copies'>
                      <input type="number" className="copies-No" min={1} value={copies} max={10000} onChange={inputNoChange}></input>
                      <div className='copies-buttons'>
                        <button onClick={increaseCopiesNumber} className="copies-no-symbol" style={{}}>&#x25B2;</button>
                        <button onClick={decreaseCopiesNumber} className="copies-no-symbol" style={{}}>&#x25BC;</button>
                      </div>
                    </div>    
                </div>
                <div className="color-options" style={{}}>
                  <div>
                    <h4 className="color-title" style={{}}>
                      Select Color
                    </h4>
                  </div>
                  <div className="color-buttons" style={{}}>
                    <button className={ color? 'selected' : ''} onClick={(e)=>{e.preventDefault(); setColor(true)}} >COLOR</button>
                    <button className={!color? 'selected' : ''} onClick={(e)=>{e.preventDefault(); setColor(false)}}>B&W</button>
                  </div>
                </div>
                <div className="papersize-options">
                  <div>
                    <h4 className="papersize-title" style={{}}>
                      Paper Size
                    </h4>
                  </div>
                  <div className="papersize-textbox" style={{}}>
                    <span className="papersize-text" style={{}}>A4</span>
                  </div>
                </div>
                <div className="side-options" style={{}}>
                  <div>
                    <h4 className="side-title" style={{}}>
                      Select Sides
                    </h4>
                  </div>
                  <div className="side-buttons" style={{}}>
                    <button className={ singleSide ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); setSingleSide(true)} } >Single side</button>
                    <button className={!singleSide ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); setSingleSide(false)}} >Duplex</button>
                  </div>
                </div>
                <div className="layout-options" style={{}}>
                  <div>
                    <h4 className="layout-title" style={{}}>
                      Select Layout
                    </h4>
                  </div>
                  <div className="layout-buttons" style={{}}>
                    <button className={ layout ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); setLayout(true)} } >Portrait</button>
                    <button className={!layout ? 'selected' : ''} onClick={(e)=>{e.preventDefault(); setLayout(false)}} >Landscape</button>
                  </div>
                </div>
                <div className="pages-options">
                  <h4 className="pages-title">Pages to Print</h4>
                  <select style={{padding:selectedPages === 'custom' ? '5px':'15px', backgroundColor:'ButtonFace' , marginBottom:'5px'}}
                      value={selectedPages}
                      onChange={(e) => setSelectedPages(e.target.value)}
                  >
                      <option value="all">All Pages</option>
                      <option value="first">First Page Only</option>
                      <option value="even">Even Pages Only</option>
                      <option value="odd">Odd Pages Only</option>
                      <option value="custom">Custom Range</option>
                  </select>
                  {selectedPages === 'custom' && (
                      <input
                          style={{width:'128px'}}
                          placeholder="e.g. 1-5"
                          value={customRange}
                          onChange={(e) => setCustomRange(e.target.value)}
                      />
                  )}
                </div>

              </div>
              <div className="final-buttons">
                <button className="upload-button" type="button" onClick={vieworder} > Select Order </button>
                <button className="upload-button" type="button" onClick={showProceedToPrint} > Proceed </button>
              </div>
            </div>
        </form>
        {showSettingsDialog && (
          <div className="modal-overlay"> 
            <div className="modal-dialog">
              {fileinfo===0?               
              <CustomMeta files={files} setFiles={setFiles} setShowSettingsDialog={setShowSettingsDialog}/>
              :<SelectOrder orders={JSON.parse(localStorage.getItem('orderIdNo'))} setShowSettingsDialog={setShowSettingsDialog} userid={globalUserId}/>
              } 
            </div>
          </div>
        )}
        {proceedToUpload && (
          <div className="modal-overlay"> 
            <div className="modal-dialog">
              <ProceedToUpload setProceedToUpload={setProceedToUpload} handleUpload={handleUpload} setPrintNow={setPrintNow}/>
            </div>
          </div>
        )}
      </section>
    )
}
