
import { signIn, userLogout } from "./userAuth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./userAuth";
import { useEffect,useState } from "react";

function Header() {
    let user = auth.currentUser;
    let userMid;
    if (user!=null){
        userMid = true;
    }
    else{
        userMid = false;
    }

    let [userState, setUserState] = useState(userMid);
   
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserState(true);
            } else {
                setUserState(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <>
            <header className="mainheader">
                <div className="image-logo-div">
                    <img className="logo-image" src="images/EROX/Website_Icon/EROX-White-Logo.png" alt="sorry"></img>
                </div>
                <nav>
                    <ul className="header-nav-list">
                        <li><a href="#home" className="header-opts" style={{}}>Home</a></li>
                        <li><a href="#aboutus" className="header-opts" style={{}}>About Us</a></li>
                        <li>{!userState?<button onClick={signIn} className="header-signin-out" style={{}}>
                            Sign In</button>:<button onClick={userLogout} className="header-signin-out" style={{}}>
                            Log Out</button>}</li>
                        <li><a href="#contact" className="header-opts" style={{textDecoration:"none"}}>Contact</a></li>
                    </ul>
                </nav>
            </header>
        </>
    );
}

export default Header   