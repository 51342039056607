import React from "react";

function HowErox(){
    return(
        <>
            <p style={{color:"white"}}>HOW EROX WORKS</p>
            <img src="images/EROX/Website_Icon/How-EROX-Works.svg" style={{height:"400px"}}></img>
        </>
    )
}

export default HowErox