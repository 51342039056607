import React ,{ useState } from "react";
import axios from 'axios';

function SelectOrder({orders, setShowSettingsDialog, userid}){
    const [selectedid, setSelectedid] = useState(null);
    const [retrigger, setRetrigger] = useState(null);
    let orderlist;
            
    if(orders!==null){
        orderlist = Object.entries(orders).map(([key,value])=>{return key;});
    }
    else{
        orderlist = [];    
    }
    
    function selectOrdertoPrint(index){
        setSelectedid(index)
    }

    async function initiatePrint(e){
        e.preventDefault();
        if(selectedid!=null){
            const ordertoprint = orderlist[selectedid];
            const filelist = orders[ordertoprint];
            
            try{const response = await axios.post(`http://web.erox.services/printit?user=${userid}&orderids=${ordertoprint}`,{filenames:filelist},{headers: {
                'Content-Type': 'application/json'
                }
            });
                console.log(response)
            }
            catch(error){
                console.log(error)
            }
            
            // fetch(`http://web.erox.services/printit?user=${userid}&orderids=${ordertoprint}`, {method:'POST', body:JSON.stringify(ordertoprint), headers: {'Content-Type': 'application/json'},}).then(response => response.json()).then(data => console.log(data))
            // .catch(error => console.error(error));
        }
        else{window.alert('select order first')}

    }
    

    function clearOrder(){
        let ordertorm = orderlist[selectedid];
        if(orders.hasOwnProperty(ordertorm)){
            delete orders[ordertorm];
            localStorage.setItem('orderIdNo',JSON.stringify(orders))
            if(!retrigger){
                setRetrigger(1);
            }
            else{
                setRetrigger(null);
            }
        } 

    }

    return(
        <>
            <div style={{display:'flex', justifyContent:'space-between' }}>
                <h3>Select Order to Print</h3>
                <button style={{height:'20px', background:'red', color:'white', border:'none' , fontWeight:'bold'}} onClick={()=>{setShowSettingsDialog(false)}}>X</button>
            </div>
            <div className="order-list">
                <ul>
                    {
                        !orders? <li><h1>no current orders found</h1></li>:Object.entries(orders).map(([prop,value],index)=>{
                            return <li key={index}>
                                    <h2 onClick={()=>{selectOrdertoPrint(index)}} style={{paddingLeft:"5px", margin:"0px", cursor:"pointer"}} 
                                    className={selectedid===index?'selected':''}>{prop}</h2>
                                    {value.map((val)=>{return <h4>{val}</h4>})}                                                                              
                                </li>
                        })
                    }
                </ul>
            </div>
            <div style={{display:"flex",justifyContent:"center",columnGap:"60px", marginTop:"50px"}}>
                <button style={{height:"50px",width:"120px",borderRadius:"10px",}} onClick={initiatePrint}> Print this order </button>
                <button style={{height:"50px",width:"120px",borderRadius:"10px",}} onClick={clearOrder}> clear this order </button>  
            </div>
        </>
    )
}

export default SelectOrder;